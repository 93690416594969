.testimonials {
  text-align: center;
  padding: 3rem 2rem;
  background-color: #ffffff;
}

.testimonials h2 {
  font-size: 2rem;
  color: #222;
  margin-bottom: 2rem;
}

.testimonial-cards {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.testimonial-card {
  width: 45%;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.testimonial-card:hover {
  transform: translateY(-5px);
}

.testimonial-card p {
  color: #666;
  font-style: italic;
}

.testimonial-card p:last-child {
  text-align: right;
  color: #333;
}

/* Responsive Testimonials */
@media (max-width: 768px) {
  .testimonial-cards {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-card {
    width: 90%;
  }
}
