.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #ff8000;
  color: #fff;
}

.navbar-logo {
  height: 85px;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

.navbar-links li {
  color: #fff;
  cursor: pointer;
}

.navbar-links li:hover {
  color: #47c7fc;
}

.navbar-cta {
  background-color: #47c7fc;
  color: #333;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.navbar-cta:hover {
  color: #fff;
  background-color: #007a5a;
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    text-align: center;
  }

  .navbar-links {
    flex-direction: column;
    gap: 1rem;
  }
}
