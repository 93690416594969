.footer {
  background-color: #222;
  color: #fff;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.footer-section, .footer-links {
  flex: 1;
  min-width: 200px;
}

.footer h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.footer-links a {
  display: block;
  color: #aaa;
  text-decoration: none;
  margin-bottom: 0.5rem;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #fff;
}

.footer p {
  color: #aaa;
  font-size: 0.9rem;
  margin-top: 1rem;
}

/* Responsive Footer */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
  }
}
