.product-showcase {
  text-align: center;
  padding: 3rem 2rem;
  background-color: #f0f0f0;
}

.product-showcase h2 {
  font-size: 2rem;
  color: #222;
  margin-bottom: 2rem;
}

.product-cards {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.product-card {
  width: 30%;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card h3 {
  color: #ff8000;
  margin-bottom: 0.5rem;
}

.product-card p {
  color: #666;
}

/* Responsive Product Showcase */
@media (max-width: 768px) {
  .product-cards {
    flex-direction: column;
    align-items: center;
  }

  .product-card {
    width: 90%;
  }
}
