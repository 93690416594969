.hero-section {
  text-align: center;
  padding: 4rem 2rem;
  background-color: #f0f0f0;
}

.hero-headline {
  font-size: 2.5rem;
  color: #222;
}

.hero-subheadline {
  font-size: 1.2rem;
  margin: 1rem 0;
  color: #666;
}

.hero-form {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.hero-input {
  padding: 0.8rem;
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
}

.hero-cta-button {
  padding: 0.8rem 1rem;
  background-color: #47c7fc;
  font-weight: 700;
  color: #333;
  border-radius: 0 4px 4px 0;
  transition: background-color 0.3s;
}

.hero-cta-button:hover {
  color: #fff;
  background-color: #007a5a;
}

/* Responsive Hero Section */
@media (max-width: 768px) {
  .hero-headline {
    font-size: 2rem;
  }

  .hero-form {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .hero-input {
    width: 80%;
    border-radius: 4px;
  }

  .hero-cta-button {
    width: 80%;
    border-radius: 4px;
    margin-top: 1rem;
  }
}
