body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  color: #333;
}

h1, h2, h3 {
  color: #222;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}

button:hover {
  background-color: #47c7fc; /* Slightly darker shade for buttons on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
  color: #ff8000;
  transition: color 0.3s;
}

a:hover {
  color: #47c7fc;
}
